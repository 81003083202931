<template>

<div class="home">
  <img class="body-background" src="ressources/mobile-body-back.png">
  <div class="overlay"></div>
  <div class="home-content">
    <img src="ressources/net-logo.png" class="net-app-logo">
    <div class="login-formsec">
          <h1 class="fogheding">Forgot Password?</h1>
            <p class="forgtxt" v-if="isSent">
                            We have sent you the link to reset the password,
                            please check your email.
                        </p>
            <p  class="forgtxt" v-else>
                Please enter your email. We will send you a link to reset your password.
            </p>
          <form>
            <div class="log-sumtsec">
              <div class="loginsec">
                <input type="text" v-model="form.email" @keyup.enter="sendResetPassword" placeholder=" Type Your Email" name="Email" required class="login-input usernm-input">
              </div>
              <div class="fogsubmit-sec">
                <button type="button" :loading="isLoading" class="login-btn" @click.prevent="sendResetPassword"
                :disabled="!form.email">Send Password Reset Link</button>
              </div>
            </div>
          </form>
          <div class="login-footersec">
              <p v-show="message"  :type="alertType" v-text="message" class="msg-txt"></p>
              <a href="/login" class="forgt-link">Login?</a>
          </div>
    </div>
</div>
</div>
</template>

<script>
import api from "@/api";

export default {
    data() {
        return {
            isSent: false,
            isLoading: null,
            form: {
                email: ""
            },
            message: "",
            alertType: "success"
        };
    },
    methods: {
        async sendResetPassword() {
            try {
                this.isLoading = true;
                var t=await api.auth.sendResetPassword(this.form);
                this.isSent = t.data.success;
                this.message = t.data.message; //"The email has been successfully sent.";
                if(t.data.success){
                    location.href = "/password/reset/t?email=" + t.data.email;
                }
            } catch (err) {
                this.message = err.response.data.errors
                    ? err.response.data.errors["email"][0]
                    : err.response.data.message;
                this.alertType = "error";
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>


<style type="scss">
    *,
    ::before,
    ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    }

    body{
    overflow:hidden;
    font-family: "Open Sans", sans-serif;
    }

    .home {
    height: 100vh;
    position: relative;
    overflow: hidden;
    }
    .body-background{
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    }
    .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background: rgba(0,0,0,0.3);
    }
    .home-content {
    max-width: 600px;
    position: relative;
    top: 60%;
    color: #fff;
    z-index: 3;
    left: 50%;
    transform: translate(-50%, -60%);
    text-align: center;
    }

    .home-content .net-app-logo {
    width: 200px;
    height: auto;
    }
    .app-logosec {
    width: 300px;
    margin: 0 auto;
    display: flex;
    }
    .login-formsec
    {
    max-width: 80%;
    margin: 0 auto;
    padding: 30px 60px 30px 60px;
    background: #ffffff82;
    }
    .login-formsec input{
    width: 100%;
    color: #fff;
    font-size: 18px;
    outline: none;
    box-shadow: none;
    }
    .log-sumtsec{
    position: relative;
    }
    .submit-sec
    {
    float: right;

    }

    .submit-sec button
    {
    background: #484848;
    border: none;
    padding: 15px;
    width: 70px;
    height: 70px;
    border-radius: 100px;
    font-size: 18px;
    color: #fff;
    }
    .loginsec
    {
    border: 15px solid #1c1e21;
    background: transparent;
    }
    .login-formsec input
    {
    width: 100%;
    padding: 10px;
    background: #1c1e21;
    border: none;
    outline:0
    }
    .login-formsec .usernm-input{
    margin-bottom: 10px;
    }
    .submit-sec
    {
    float: right;
    position: absolute;
    right: -30px;
    bottom: 30px;
    }
    .forgt-link
    {
    display: block;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    margin: 25px 0 15px 0;
    }
    .cret-accnt
    {
    display: block;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;

    }
    .msg-txt
    {
    font-size: 16px;
    margin: 20px 0 0 20px;
    }
    .loginsec .login-input::placeholder{
    color:#f5f5f5;
    font-size: 14px;
    }
    .forgt-link span{
    font-weight:700;
    }
    .fogheding {
    font-size: 30px;
    padding: 0 0 20px 0;
    }
    .forgtxt {
    font-size: 16px;
    padding: 0 20px 20px 20px;
    line-height: 25px;
    }
    .fogsubmit-sec .login-btn {
    padding: 13px 20px;
    margin-top: 15px;
    width: 100%;
    background: #484848;
    color: #fff;
    border: 1px solid #484848;
    font-size: 18px;
    font-weight: 500;
    }

    .forgt-loglink{
    display: block;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    margin: 25px 0 15px 0;
    }
    @media screen and (min-device-width: 300px) and (max-device-width: 500px) {
    .login-formsec
    {
    max-width: 100%;
    margin-top: 30px;

    }
    .submit-sec
    {

    right: -35px;
    bottom: 25px;
    }
    }

</style>